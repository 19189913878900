const router = [
	{
		path: "/setting/layout",
		component: () => import('../../../pages/setting/Layout.vue'),
		meta: { title: '设置' },
		name: 'setting_layout',
		children: [
			{
				path: "/setting",
				component: () => import('../../../pages/setting/Index.vue'),
				meta: { title: '站点基本设置' },
				name: 'setting_index',
			},
			{
				path: "/setting/kefu",
				component: () => import('../../../pages/setting/Kefu.vue'),
				meta: { title: '客服' },
				name: 'setting_kefu',
			},
			{
				path: "/setting/oss",
				component: () => import('../../../pages/setting/Oss.vue'),
				meta: { title: '文件存储设置' },
				name: 'setting_oss'
			},
			{ 
				path: "/setting/open",
				component: () => import('../../../pages/setting/Open.vue'),
				meta: { title: '小程序设置' },
				name: 'setting_open'
			},
			{
				path: "/setting/message",
				component: () => import('../../../pages/setting/message.vue'),
				meta: { title: '订阅消息' },
				name: 'setting_message'
			},
			{
				path: "/setting/payment",
				component: () => import('../../../pages/setting/Payment.vue'),
				meta: { title: '支付设置' },
				name: 'setting_payment'
			},
			{
				path: "/setting/smssetting",
				component: () => import('../../../pages/setting/components/sms/setting.vue'),
				meta: { title: '短信配置' },
				name: 'setting_sms_setting'
			},
			{
				path: "/setting/smstpl",
				component: () => import('../../../pages/setting/components/sms/tpl.vue'),
				meta: { title: '模板配置' },
				name: 'setting_sms_tpl'
			},
			{
				path: "/setting/userInfo",
				component: () => import('../../../pages/setting/userInfo.vue'),
				meta: { title: '账号管理' },
				name: 'setting_user_info'
			}
		]
	}
];

export default router