const  router = [
	{
		path: "/application/layout",
		component: () => import('../../../pages/application/Layout.vue'),
		meta: {title: '应用'},
		name: 'application_layout',
		children:[
			{
				path: "/application",
				component: () => import('../../../pages/application/Index.vue'),
				meta: {title: '上架中'},
				name: 'application_index',
			},
			{
				path: "/application/developing",
				component: () => import('../../../pages/application/Developing.vue'),
				meta: {title: '开发中'},
				name: 'application_developing',
			},
			{
				path: "/application/activationCode",
				component: () => import('../../../pages/application/activationCode.vue'),
				meta: {title: '激活码'},
				name: 'application_activation_code',
			},
			{
				path: "/application/plugin",
				component: () => import('../../../pages/application/Plugin.vue'),
				meta: {title: '插件中心'},
				name: 'application_plugin',
			},
			{
				path: "/application/radar",
				component: () => import('../../../pages/application/Radar.vue'),
				meta: {title: '企业微信雷达'},
				name: 'application_radar',
			},
			{
				path: "/application/yoga",
				component: () => import('../../../pages/application/Yoga.vue'),
				meta: {title: '瑜伽多门店'},
				name: 'application_yoga',
			},
			{
				path: "/application/beautiful",
				component: () => import('../../../pages/application/Beautiful.vue'),
				meta: {title: '美业多门店'},
				name: 'application_beautiful',
			},
			{
				path: "/application/auto",
				component: () => import('../../../pages/application/Auto.vue'),
				meta: {title: '汽修多门店'},
				name: 'application_auto',
			},
			{
				path: "/application/teahouse",
				component: () => import('../../../pages/application/Teahouse.vue'),
				meta: {title: '棋牌室多门店'},
				name: 'application_teahouse',
			},
			{
				path: "/application/edit",
				component: () => import('../../../pages/application/Edit.vue'),
				meta: {title: '编辑应用'},
				name: 'application_index_edit',
			}
		]
		
	}
	
];

export default router