<template>
  <div id="app" :class="[{ dark: isDark }]">
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
    <!-- <div class="them-taggle">
      <span
        :class="['them-item', { 'c-active': !isDark }]"
        @click="isDark = false"
        >默认模式</span
      >
      <a-switch v-model:checked="isDark" />
      <span
        :class="['them-item', { 'c-active': isDark }]"
        @click="isDark = true"
        >黑夜模式</span
      >
    </div> -->
  </div>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
  computed: {
    isDark: {
      get() {
        return this.$store.state.them.isDark;
      },
      set(val) {
        this.$store.commit("them/setIsDark", val);
      },
    },
  },
  created() {
    //document.getElementById("woyaou-loading").remove();
  },
};
</script>

<style>
#app {
  background: #e5e5ef;
  min-height: 100vh;
  .them-taggle {
    position: fixed;
    bottom: 25px;
    right: 30px;
    .them-item {
      padding: 10px;
      cursor: pointer;
    }
    .c-active {
      color: #8878ea;
    }
  }

}
.ant-modal-content{
    background-color: rgba(255, 255, 255, 0.1) !important;
    backdrop-filter: blur(10px);
  }
  .ant-modal-header{
    background-color: rgba(255, 255, 255, 0.1) !important;
    border-bottom:none !important;
  }
  .ant-modal-footer{
    border-top:none !important;
  }
  .ant-modal-title{
    color: rgb(255 255 255)!important;
  }
  .ant-modal-content .ant-input{
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid #ffffff00 !important;
  }
  .ant-modal-content .ant-input::placeholder{
    color: #ffffff!important;
  }
  .ant-select-selection{
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid #ffffff00 !important;
    color: #ffffff!important;
  }
  .ant-select-dropdown-menu-item-selected {
    font-weight: 600;
    color: #ffffff!important;
    background-color: rgba(255, 255, 255, 0.1) !important;
}
.ant-select-dropdown-menu-item{
  color: #ffffff!important;
}
.ant-select-dropdown{
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){
  background-color:rgba(255, 255, 255, 0.2) !important;
}
.ant-table .more-act .iconfont{
  color: #333!important;
}
.ant-table .iconfont:hover{
  color: #2f2d2d!important;
}
  /* .ant-input-prefix{
    color: #000!important;
  } */
</style>
