const  router = [
	{
		path: "/shop/layout",
		component: () => import('../../../pages/shop/Layout.vue'),
		meta: {title: '小程序'},
		name: 'shop_layout',
		children:[
			{
				path: "/shop",
				component: () => import('../../../pages/shop/Index.vue'),
				meta: {title: '使用中'},
				name: 'shop_online',
			},
			{
				path: "/shop/notice",
				component: () => import('../../../pages/shop/Notice.vue'),
				meta: {title: '即将到期'},
				name: 'shop_notice',
			},
			{
				path: "/shop/test",
				component: () => import('../../../pages/shop/Test.vue'),
				meta: {title: '试用中'},
				name: 'shop_test',
			},
			{
				path: "/shop/expire",
				component: () => import('../../../pages/shop/Expire.vue'),
				meta: {title: '已失效店铺'},
				name: 'shop_expire',
			},
			{
				path: "/shop/kefu",
				component: () => import('../../../pages/shop/Kefu.vue'),
				meta: {title: '客服'},
				name: 'shop_kefu',
			},
			{
				path: "/shop/free",
				component: () => import('../../../pages/shop/free.vue'),
				meta: {title: '免认证'},
				name: 'free',
			},
			{
				path: "/shop/plugin",
				component: () => import('../../../pages/shop/Plugin.vue'),
				meta: {title: '插件中心'},
				name: 'shop_plugin',
			},
			{
				path: "/shop/radar",
				component: () => import('../../../pages/application/Radar.vue'),
				meta: {title: '企业微信雷达'},
				name: 'shop_radar',
			},
			{
				path: "/shop/yoga",
				component: () => import('../../../pages/application/Yoga.vue'),
				meta: {title: '瑜伽多门店'},
				name: 'shop_yoga',
			},
			{
				path: "/shop/beautiful",
				component: () => import('../../../pages/application/Beautiful.vue'),
				meta: {title: '美业多门店'},
				name: 'shop_beautiful',
			},
			{
				path: "/shop/auto",
				component: () => import('../../../pages/application/Auto.vue'),
				meta: {title: '汽修多门店'},
				name: 'shop_auto',
			},
			{
				path: "/shop/teahouse",
				component: () => import('../../../pages/application/Teahouse.vue'),
				meta: {title: '棋牌室多门店'},
				name: 'shop_teahouse',
			}
		]
		
	}
];

export default router