export default {
  namespaced: true,
  state: {
    isDark: !!+localStorage.getItem("them") || false,
  },
  mutations: {
    setIsDark(state, param) {
      state.isDark = param;
      localStorage.setItem('them',+param)
    },
  },
};
